export default function () {
    const modals = document.querySelectorAll('.js-modal');

    modals.forEach((modal) => {
        const openButtons = document.querySelectorAll(`.js-open-modal[data-modal="${modal.id}"]`);
        const closeButton = modal.querySelector('.js-close-modal');
        const body = document.querySelector('body');

        const positionModal = (button) => {
            const buttonRect = button.getBoundingClientRect();
            const modalRect = modal.getBoundingClientRect();
            const viewportHeight = window.innerHeight;
            const viewportWidth = window.innerWidth;

            // Position vertikal - unter dem Button (oder darüber, wenn kein Platz)
            let top = buttonRect.bottom + window.scrollY + 5;

            // Position horizontal - Rechte Kante des Modals an der rechten Kante des Buttons ausrichten
            // Das Modal öffnet sich damit nach links
            const right = viewportWidth - (buttonRect.right + window.scrollX);

            // Wenn unten nicht genug Platz ist, über dem Button positionieren
            if (top + modalRect.height > window.scrollY + viewportHeight) {
                top = buttonRect.top + window.scrollY - modalRect.height - 5;
            }

            // Sicherstellen, dass das Modal horizontal nicht über den Bildschirmrand hinausragt
            if (buttonRect.right - modalRect.width < 0) {
                // Wenn links nicht genug Platz ist, beim linken Bildschirmrand beginnen
                const modalCopy = modal;
                modalCopy.style.left = '0px';
                modalCopy.style.right = 'auto';
            } else {
                // Sonst rechts vom Button positionieren und nach links öffnen
                const modalCopy = modal;
                modalCopy.style.right = `${right}px`;
                modalCopy.style.left = 'auto';
            }

            const modalCopy = modal;
            modalCopy.style.top = `${top}px`;
        };

        const positionFixedModal = (button) => {
            const buttonRect = button.getBoundingClientRect();
            const modalCopy = modal;

            // Für fixed positionierte Modals: Koordinaten relativ zum Viewport
            modalCopy.style.top = `${buttonRect.bottom + 5}px`;
            modalCopy.style.right = `${window.innerWidth - buttonRect.right}px`;
            modalCopy.style.left = 'auto';
        };

        const openModal = (event) => {
            event.stopPropagation();
            const clickedButton = event.currentTarget;
            modal.classList.add('open');

            // Positionierung basierend auf dem data-position Attribut
            if (modal.dataset.position === 'fixed') {
                const modalCopy = modal;
                modalCopy.style.position = 'fixed';
                positionFixedModal(clickedButton);

                // Update position on window resize for fixed modals
                window.addEventListener('resize', () => {
                    if (modal.classList.contains('open')) {
                        positionFixedModal(clickedButton);
                    }
                });
            } else {
                // Standardverhalten für reguläre Modals (absolute Positionierung)
                const modalCopy = modal;
                modalCopy.style.position = 'absolute';

                if (modal.dataset.position === 'auto') {
                    positionModal(clickedButton);
                }

                // Update position on window resize for regular modals
                window.addEventListener('resize', () => {
                    if (modal.classList.contains('open')) {
                        positionModal(clickedButton);
                    }
                });
            }
        };

        const closeModal = () => {
            modal.classList.remove('open');

            setTimeout(() => {
                // Reset position styles when closing
                const modalCopy = modal;
                modalCopy.style.position = '';
                modalCopy.style.top = '';
                modalCopy.style.right = '';
                modalCopy.style.left = '';
            }, 200);

            // Resize-Listener entfernen
            window.removeEventListener('resize', positionModal);
            window.removeEventListener('resize', positionFixedModal);
        };

        if (openButtons) {
            openButtons.forEach((openButton) => {
                openButton.addEventListener('click', openModal);
            });
        }

        if (closeButton) {
            closeButton.addEventListener('click', closeModal);
        }

        body.addEventListener('click', (event) => {
            const isClickInsideModal = modal.contains(event.target);
            let isClickOnOpenButton = false;

            openButtons.forEach((openButton) => {
                if (!isClickOnOpenButton) {
                    isClickOnOpenButton = openButton.contains(event.target);
                }
            });

            if (!isClickInsideModal && !isClickOnOpenButton) {
                closeModal();
            }
        });
    });
}
